import { AWS_CONFIG } from 'src/config-global';

const AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: AWS_CONFIG.id,
  secretAccessKey: AWS_CONFIG.key,
  region: AWS_CONFIG.region
});

export default AWS;
