import { useCallback } from 'react';

import { dropUser } from 'src/api/user';

import { useSnackbar } from 'src/components/snackbar';

export const useDropUser = () => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteUser = useCallback(
    async (id, user) => {
      try {
        await dropUser(id, user.id);
        enqueueSnackbar('Delete success!', { variant: 'success' });
        return { success: true, error: null };
      } catch (error) {
        enqueueSnackbar(`Deletion failed: ${error.message}`, { variant: 'error' });
        return { success: false, error };
      }
    },
    [enqueueSnackbar]
  );

  return { deleteUser };
};
