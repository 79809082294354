import { useCallback } from 'react';

import { dropLog } from 'src/api/log';

import { useSnackbar } from 'src/components/snackbar';

export const useDropLog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteLog = useCallback(
    async (id, user) => {
      try {
        await dropLog(id, user);
        enqueueSnackbar('Delete success!', { variant: 'success' });
        return { success: true, error: null };
      } catch (error) {
        enqueueSnackbar(`Deletion failed: ${error.message}`, { variant: 'error' });
        return { success: false, error };
      }
    },
    [enqueueSnackbar]
  );

  return { deleteLog };
};
