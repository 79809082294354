import { supabase } from 'src/auth/context/supabase/lib';
import { uploadPhotoToS3, deletePhotoFromS3 } from 'src/services/aws';
import { facerecReg } from 'src/services/facerec';
import { checkUserDeletePermission, checkUserEditPermission } from './permission';

// ----------------------------------------------------------------------

export const updateAuthUser = async (userData, uuid) => {
  const updates = Object.keys(userData).reduce((acc, key) => {
    acc[key] = userData[key];
    return acc;
  }, {});

  const { _, error } = await supabase.from('users')
    .update(updates)
    .eq('authID', uuid);

  if (error) {
    throw new Error(error.message);
  }

  return _;
};

// ----------------------------------------------------------------------

export const getAuthUser = async (userID) => {
  const { data, error } = await supabase
    .from('users')
    .select(`*, auth_users(email, raw_user_meta_data->display_name)`)
    .eq('authID', userID);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// ----------------------------------------------------------------------

export const getUserList = async () => {
  const { data, error } = await supabase
    .from('users')
    .select('*')
    .is('authID', null);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// ----------------------------------------------------------------------

export const createRegUser = async (faceID, name, imagePath) => {
  const requestData = {
    name_pic: name,
    face_id: faceID,
    image_path: imagePath,
  };

  try {
    const result = await facerecReg('reg', requestData);
    return result;
  } catch (error) {
    throw new Error(`Create registered user, ${error.message}`);
  }
};

// ----------------------------------------------------------------------

export const updateUser = async (id, columns, user) => {
  await checkUserEditPermission(user)
  
  const { data, error } = await supabase
    .from('users')
    .update(columns)
    .eq('id', id)
    .select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// ----------------------------------------------------------------------

export const getUserRoles = async () => {
  const { data, error } = await supabase
    .from('users')
    .select('role')
    .is('authID', null)
    .neq('role', null);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// ----------------------------------------------------------------------

export const dropUser = async (id, user) => {
  await checkUserDeletePermission(user);

  const { errorFaceDB } = await supabase
    .from('face_db')
    .delete()
    .eq('face_id', id)

  if (errorFaceDB) {
    throw new Error(error.message);
  }

  const { error } = await supabase
    .from('users')
    .delete()
    .eq('id', id)
    .select()

  if (error && error.message.includes('foreign key constraint')) {
    throw new Error('Failed to delete user due to existing dependencies');
  } else if (error) {
    throw new Error(error.message);
  }

  await deletePhotoFromS3(
    'aws-facerec-gim-security-590183751536-uyxom',
    `facerec-store/${id}.png`
  )
}

// ----------------------------------------------------------------------

export const dropUserBulk = async (ids, user) => {
  await checkUserDeletePermission(user);

  const { errorFaceDB } = await supabase
    .from('face_db')
    .delete()
    .in('face_id', ids)


  if (errorFaceDB) {
    throw new Error(error.message);
  }

  const { error } = await supabase
    .from('users')
    .delete()
    .in('id', ids)

  if (error && error.message.includes('foreign key constraint')) {
    throw new Error('Failed to delete user due to existing dependencies');
  } else if (error) {
    throw new Error(error.message);
  }

  await deletePhotoFromS3(
    'aws-facerec-gim-security-590183751536-uyxom',
    `facerec-store/${ids}.png`
  )
}

// ----------------------------------------------------------------------

export const postUser = async (userData) => {
  // Deep copy userData into inserts
  const inserts = { ...userData };
  let id = null;

  if (inserts.photoData) {
    try {
      // Insert a new user and get the faceID
      const { data, error } = await supabase
        .from('users')
        .insert([{}])
        .select('id')
        .single();

      // Handle errors if any
      if (error) {
        throw new Error(error.message);
      }

      id = data.id;

      // Upload photo to S3
      const photoURL = await uploadPhotoToS3(
        inserts.photoData,
        'aws-facerec-gim-security-590183751536-uyxom',
        `facerec-store/${id}.png`
      );

      // Remove photoData from inserts and add photoURL
      delete inserts.photoData;
      inserts.photoURL = photoURL;

      const { _, regError } = await createRegUser(id, inserts.name, photoURL);

      if (regError) {
        throw new Error(regError.message);
      }

      // Update user data with photoURL and other user details
      const { data: updatedData, error: updateError } = await supabase
        .from('users')
        .update(inserts)
        .eq('id', id)
        .select();

      // Handle errors if any
      if (updateError) {
        throw new Error(updateError.message);
      }

      // Return the updated data
      return updatedData[0];
    } catch (error) {
      if (id) {
        // Delete the user if faceID is defined
        const { error: deleteError } = await supabase
          .from('users')
          .delete()
          .eq('id', id);

        // Handle errors if any
        if (deleteError) {
          throw new Error(deleteError.message);
        }
        await deletePhotoFromS3(
          'aws-facerec-gim-security-590183751536-uyxom',
          `facerec-store/${id}.png`
        )
      }
      throw new Error(`${error.message}`);
    }
  } else {
    throw new Error('Photo data is required to insert a user.');
  }
};
