import { useCallback } from 'react';

import { updateLog } from 'src/api/log';

import { useSnackbar } from 'src/components/snackbar';

export const usePatchLog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const patchLog = useCallback(
    async (id, column) => {
      try {
        delete(column.photoData)
        await updateLog(id, column);
        enqueueSnackbar('Update success!', { variant: 'success' });
        return { success: true, error: null };
      } catch (error) {
        enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
        return { success: false, error };
      }
    },
    [enqueueSnackbar]
  );

  return { patchLog };
};
