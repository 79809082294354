import { useCallback } from 'react';

import { getLogList } from 'src/api/log';

import { useSnackbar } from 'src/components/snackbar';

export const useGetLogList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const getLogData = useCallback(async () => {
    try {
      const userData = await getLogList();
      return { data: userData, error: null };
    } catch (error) {
      enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
      return { data: null, error };
    }
  }, [enqueueSnackbar]);

  return { getLogData };
};
