import { useEffect, useState } from 'react';

import { getLogAuthorities } from 'src/api/log';

import { useSnackbar } from 'src/components/snackbar';

export const useGetLogAuthorities = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ authorities, setAuthorities] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLogAuthorities();

        const filteredRoles = [...new Set(data.map(Obj => Obj.users.authorityName))];
        setAuthorities(filteredRoles);        
      } catch (error) {
        enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
      }
    };

    fetchData();
  }, [enqueueSnackbar]);

  return { authorities }; 
};
