import { useCallback } from 'react';

import { getUserList } from 'src/api/user';

import { useSnackbar } from 'src/components/snackbar';

export const useGetUserList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const getUserData = useCallback(async () => {
    try {
      const userData = await getUserList();
      return { data: userData, error: null };
    } catch (error) {
      enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
      return { data: null, error };
    }
  }, [enqueueSnackbar]);

  return { getUserData };
};
