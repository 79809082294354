export const section = [
    { label: ''},
    { label: 'Server Room' },
    { label: 'Office' },
    { label: 'Slitting' },
    { label: 'Rewinding' },
    { label: 'Coating' },
    { label: 'Inkjet' },
    { label: 'QC Lab & QC related' },
    { label: 'Factory Facilities' },
];
