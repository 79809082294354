export const position = [
    { label: ''},
    { label: 'Chief Officer' },
    { label: 'Director' },
    { label: 'Manager' },
    { label: 'Engineer' },
    { label: 'Executive' },
    { label: 'Administrator' },
    { label: 'Analyst' },
    { label: 'Assistant' },
    { label: 'Associate' },
    { label: 'Consultant' },
    { label: 'Coordinator' },
    { label: 'Designer' },
    { label: 'Developer' },
    { label: 'Head of Department' },
    { label: 'Intern' },
    { label: 'Lead' },
    { label: 'Marketing Specialist' },
    { label: 'Product Manager' },
    { label: 'Project Manager' },
    { label: 'Sales Representative' },
    { label: 'Specialist' },
    { label: 'Supervisor' },
    { label: 'Technician' },
    { label: 'Team Leader' },
    { label: 'Vice President' }
];
