import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { supabase } from 'src/auth/context/supabase/lib';

import { useGetUserList } from 'src/sections/user/hooks';
import { useGetLogList } from '../hooks';

export const LogListContext = createContext();

export const LogListProvider = ({ children }) => {
  const { getLogData } = useGetLogList();
  const { getUserData } = useGetUserList();
  const [logList, setLogList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: logData, error: logError } = await getLogData();
        if (logError) {
          throw new Error(`Error fetching log data: ${logError.message}`);
        }

        const { data: userData, error: userError } = await getUserData();
        if (userError) {
          throw new Error(`Error fetching user data: ${userError.message}`);
        }

        setLogList(logData);
        setUserList(userData);
      } catch (err) {
        console.error('Error fetching data:', err.message);
      }
    };

    fetchData();

    const subscription = supabase
      .channel('public:logs')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'users' }, (payload) => {
        // console.log('Realtime changes triggered', payload);
        fetchData();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [getLogData, getUserData]);

  const contextValue = useMemo(() => ({ logList, userList }), [logList, userList]);

  return <LogListContext.Provider value={contextValue}>{children}</LogListContext.Provider>;
};

LogListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLogListContext = () => useContext(LogListContext);
