import { supabase } from "src/auth/context/supabase/lib";

export const checkUserDeletePermission = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('access_permissions')
      .select('users_delete')
      .eq('user_id', userId)
      .single();

    if (error || !data || data.users_delete !== true) {
      throw new Error('Failed to check permissions or no permission granted');
    }

    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};

// ----------------------------------------------------------------------

export const checkUserEditPermission = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('access_permissions')
      .select('users_edit')
      .eq('user_id', userId)
      .single();

    if (error || !data || data.users_edit !== true) {
      throw new Error('Failed to check permissions or no permission granted');
    }

    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};

// ----------------------------------------------------------------------

export const checkPermission = async (userId, columnName) => {
  try {
    const { data, error } = await supabase
      .from('access_permissions')
      .select(columnName)
      .eq('user_id', userId)
      .single();

    if (error || !data || data[columnName] !== true) {
      throw new Error('Failed to check permissions or no permission granted');
    }

    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};

