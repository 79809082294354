import { useCallback, useState } from 'react';

import { postUser } from 'src/api/user';

import { useSnackbar } from 'src/components/snackbar';

export const usePostUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [upsert, setUpsert] = useState(null);

  const insertUserData = useCallback(
    async (userData, currentUser, reset) => {
      try {
        const upsertData = await postUser(userData);
        enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!', {
          variant: 'success',
        });
        setUpsert(upsertData);
        reset();
        return { data: upsertData, error: null };
      } catch (error) {
        if (!error.message || error.message.trim() === '') {
          error.message = 'Not Found Error 404';
        }
        enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
        return { data: null, error: error.message };
      }
    },
    [enqueueSnackbar]
  );

  return { insertUserData, upsert };
};
