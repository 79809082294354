import configureApi from './config';

export const facerecReg = async (endpoint, requestData) => {
  const apiConfig = configureApi();

  const requestOptions = {
    method: 'POST',
    headers: apiConfig.headers,
    body: JSON.stringify(requestData),
    redirect: 'follow',
    // mode: 'no-cors',
  };

  try {
    const response = await fetch(`${apiConfig.baseUrl}${endpoint}`, requestOptions);

    if (!response.ok) {
      let errorMessage = `HTTP error! Status ${response.status}`;
      try {
        const responseData = await response.json();
        if (responseData && responseData.message) {
          errorMessage += `; ${responseData.message}`;
        }
      } catch (error) {
        console.error('Error parsing JSON response:', error);
      }
      throw new Error(errorMessage);
    }

    const result = await response.text();
    return result;
  } catch (error) {
    throw new Error(`Facerec API: ${error.message}`);
  }
};
