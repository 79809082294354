import { supabase } from 'src/auth/context/supabase/lib';
import { facerecReg } from 'src/services/facerec';
import { checkPermission } from './permission';
// ----------------------------------------------------------------------

export const getLogList = async () => {
  const { data, error } = await supabase.from('logs').select('*, users(*)');

  if (error) {
    throw new Error(error.message);
  }

  if (data) {
    data.forEach((log) => {
      if (log.timeIn) {
        const date = new Date(log.timeIn);
        log.unformattedIn = log.timeIn;
        log.timeIn = date.toLocaleTimeString('en-SG', {
          timeZone: 'Asia/Singapore',
          hour: '2-digit',
          minute: '2-digit',
        });
      }
      if (log.timeOut) {
        const date = new Date(log.timeOut);
        log.unformattedOut = log.timeOut;
        log.timeOut = date.toLocaleTimeString('en-SG', {
          timeZone: 'Asia/Singapore',
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    });
  }

  return data;
};

// ----------------------------------------------------------------------

export const createRegUser = async (faceID, name, imagePath) => {
  const requestData = {
    name_pic: name,
    face_id: faceID,
    image_path: imagePath,
  };

  try {
    const result = await facerecReg('reg', requestData);
    return result;
  } catch (error) {
    throw new Error(`Create registered user, ${error.message}`);
  }
};

// ----------------------------------------------------------------------

export const updateLog = async (id, columns) => {
  const {
    name,
    authorityName,
    phoneNumber,
    icPassport,
    photoURL,
    photoData,
    unformattedIn,
    unformattedOut,
    userID,
    timeOut,
    timeIn,
    ...others
  } = columns;

  if (timeOut) {
    others.timeOut = formatTime(timeOut);
  }

  const { data, error } = await supabase
    .from('logs')
    .update(others)
    .eq('id', id).select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// ----------------------------------------------------------------------

export const getLogAuthorities = async () => {
  const { data, error } = await supabase
    .from('logs')
    .select('users(authorityName)')
    .neq('status', 'quitted');

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// ----------------------------------------------------------------------

export const dropLog = async (id, user) => {
  await checkPermission(user, 'list_delete')
  const { error } = await supabase
    .from('logs')
    .delete()
    .eq('id', id);

  if (error) {
    throw new Error(error.message);
  }
};

// ----------------------------------------------------------------------

export const dropLogBulk = async (ids, user) => {
  await checkPermission(user, 'list_delete')
  const { error } = await supabase
    .from('logs')
    .delete()
    .in('id', ids);

  if (error) {
    throw new Error(error.message);
  }
};

// ----------------------------------------------------------------------

export const postLog = async (userData) => {
  // Destructure the properties to exclude from userData
  const { photoData, authorityName, photoURL, icPassport, name, timeIn, timeOut, ...inserts } =
    userData;

  // Format timeIn if it exists in userData
  if (timeIn) {
    inserts.timeIn = formatTime(timeIn);
  }

  // Convert timeOut if it exists
  if (timeOut) {
    inserts.timeOut = formatTime(timeOut);
  }

  // Insert the remaining properties into the logs table
  const { error: insertError } = await supabase
    .from('logs')
    .insert(inserts);

  // Handle errors if any
  if (insertError) {
    throw new Error(insertError.message);
  }
};

// ----------------------------------------------------------------------

// Function to convert a given time string to PostgreSQL timestamptz format
const formatTime = (time) => {
  // Ensure time has at least 8 characters (HH:mm:ss)
  const formattedTime = time.padStart(8, '0');
  return `${formattedTime}`;
};
