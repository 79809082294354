// ----------------------------------------------------------------------

export function decodeBase64ToBinary(base64String) {
    // Extract base64 part from the data URI
    const base64Data = base64String.split(',')[1];

    // Convert base64 to binary
    const binaryString = atob(base64Data);

    // Create an ArrayBuffer from binary string
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const bytes = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i+=1) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
}


// ----------------------------------------------------------------------

export function decodeBase64ToNumPy(base64String) {
    // Extract base64 part from the data URI
    const base64Data = base64String.split(',')[1];
    
    // Convert base64 to binary
    const binaryString = atob(base64Data);
    
    // Create array to hold binary data
    const bytes = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i+=1) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    
    return bytes;
}

// ----------------------------------------------------------------------

export function decodeBase64ToHex(base64String) {
    // Extract base64 part from the data URI
    const base64Data = base64String.split(',')[1];

    // Convert base64 to binary
    const binaryString = atob(base64Data);

    // Convert binary to hex
    let hexString = '';
    for (let i = 0; i < binaryString.length; i+=1) {
        const hex = binaryString.charCodeAt(i).toString(16).padStart(2, '0');
        hexString += hex;
    }

    return hexString;
}

// ----------------------------------------------------------------------

export function decodeBase64ToBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i+=1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }