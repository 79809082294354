import { useCallback } from 'react';

import { dropLogBulk } from 'src/api/log';

import { useSnackbar } from 'src/components/snackbar';

export const useDropLogBulk = () => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteLogBulk = useCallback(
    async (ids, user) => {
      try {
        await dropLogBulk(ids, user);
        enqueueSnackbar('Delete success!', { variant: 'success' });
        return { success: true, error: null };
      } catch (error) {
        enqueueSnackbar(`Deletion failed: ${error.message}`, { variant: 'error' });
        return { success: false, error };
      }
    },
    [enqueueSnackbar]
  );

  return { deleteLogBulk };
};
