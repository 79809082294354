import { useCallback } from 'react';

import { updateUser } from 'src/api/user';

import { useSnackbar } from 'src/components/snackbar';

export const usePatchUser = () => {
  const { enqueueSnackbar } = useSnackbar();

  const patchUser = useCallback(
    async (id, column, user) => {
      try {
        delete(column.photoData)
        await updateUser(id, column, user);
        enqueueSnackbar('Update success!', { variant: 'success' });
        return { success: true, error: null };
      } catch (error) {
        enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
        return { success: false, error };
      }
    },
    [enqueueSnackbar]
  );

  return { patchUser };
};
