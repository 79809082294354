import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { supabase } from 'src/auth/context/supabase/lib';
import { useGetUserList } from '../hooks';

export const UserListContext = createContext();

export const UserListProvider = ({ children }) => {
  const { getUserData } = useGetUserList();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await getUserData();
        if (error) {
          throw new Error(`Error fetching user data: ${error.message}`);
        }
        setUserList(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();

    const subscription = supabase
      .channel('public:users')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'users' }, payload => {
        // console.log('Realtime changes triggered', payload);
        fetchData();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [getUserData]);

  const contextValue = useMemo(() => ({ userList }), [userList]);

  return (
    <UserListContext.Provider value={contextValue}>
      {children}
    </UserListContext.Provider>
  );
};

UserListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserListContext = () => useContext(UserListContext);
