const unsortedVehicle = [
    { label: ''},
    { label: 'Toyota' },
    { label: 'Honda' },
    { label: 'Produa' },
    { label: 'Proton' },
    { label: 'Mazda' },
    { label: 'Lexus' },
    { label: 'Mercedes' },
    { label: 'BMW' },
    { label: 'Nissan' },
    { label: 'Mitsubishi' },
    { label: 'Audi' },
    { label: 'Hyundai' },
    { label: 'Ford' },
    { label: 'Haval' },
    { label: 'Daihatsu' },
    { label: 'Isuzu' },
    { label: 'Hyundai' },
    { label: 'KIA' },
    { label: 'Cherry' },
    { label: 'BYD' },
    { label: 'Tesla' },
];

function sortStaffAlphabetically(staffArray) {
    return staffArray.sort((a, b) => {
        const labelA = a.label.toUpperCase(); // Ignore case for comparison
        const labelB = b.label.toUpperCase(); // Ignore case for comparison
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
}

export const vehicle = sortStaffAlphabetically(unsortedVehicle);

