import AWS from "./config";

const s3 = new AWS.S3();

async function getPhotoFromS3(bucketName, keyName) {
  const params = {
    Bucket: bucketName,
    Key: keyName,
  };

  try {
    const data = await s3.getObject(params).promise();
    // Convert the buffer to base64
    const base64Data = data.Body.toString('base64');
    // Construct data URI
    const dataUri = `data:${data.ContentType};base64,${base64Data}`;
    return dataUri;
  } catch (err) {
    throw new Error(err.message);
  }
}

// Export function
export { getPhotoFromS3 };


// ----------------------------------------------------------------------

function parseS3Url(s3Url) {
  try {
    const url = new URL(s3Url);
    const hostnameParts = url.hostname.split('.');

    if (hostnameParts.length < 3 || hostnameParts[1] !== 's3') {
      throw new Error('Invalid S3 URL');
    }

    const bucketName = hostnameParts[0];
    const objectPath = url.pathname.slice(1); // Remove leading slash

    return { bucketName, objectPath };
  } catch (error) {
    throw new Error('Invalid S3 URL');
  }
}

// usage:

// const s3Url = currentUser?.photoURL;
// try {
//   const { bucketName, objectPath } = parseS3Url(s3Url);
//   const photo = getPhotoFromS3(bucketName, objectPath);
// } catch (error) {
//   console.error(error.message);
// }