const unsortedStaff = [
    { label: ''},
    { label: 'Yonezawa' },
    { label: 'Yee' },
    { label: 'Yeap' },
    { label: 'Kiung' },
    { label: 'Azmin' },
    { label: 'Arita' },
    { label: 'Syara' },
    { label: 'Haffiz' },
    { label: 'Liza' },
    { label: 'Lily' },
    { label: 'Atteya' },
    { label: 'Atikah' },
    { label: 'Leong' },
    { label: 'Jun' },
    { label: 'KC' },
    { label: 'Syima' },
    { label: 'Wong' },
    { label: 'Mazidah' },
    { label: 'Noraini' },
    { label: 'Syafiqah' },
    { label: 'NurSyafikah' },
    { label: 'Tan' },
    { label: 'Naito' },
    { label: 'Din' },
    { label: 'Hisyam' },
    { label: 'Mat' },
    { label: 'Suzy' },
    { label: 'Yati' },
    { label: 'Norzila' },
    { label: 'Coating Azmi' },
    { label: 'Raja' },
];

function sortStaffAlphabetically(staffArray) {
    return staffArray.sort((a, b) => {
        const labelA = a.label.toUpperCase(); // Ignore case for comparison
        const labelB = b.label.toUpperCase(); // Ignore case for comparison
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
}

export const staff = sortStaffAlphabetically(unsortedStaff);

