import { FACEREC_API } from 'src/config-global';

const configureApi = () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", FACEREC_API.key);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Host", FACEREC_API.host);
  myHeaders.append("Connection", "keep-alive");

  return {
    baseUrl: FACEREC_API.url,
    headers: myHeaders
  };
};

export default configureApi;
