import { useEffect, useState } from 'react';

import { getUserRoles } from 'src/api/user';

import { useSnackbar } from 'src/components/snackbar';

export const useGetUserRoles = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ roles, setRoles] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserRoles();

        const filteredRoles = [...new Set(data.map(roleObj => roleObj.role))];
        setRoles(filteredRoles);        
      } catch (error) {
        enqueueSnackbar(`Update failed: ${error.message}`, { variant: 'error' });
      }
    };

    fetchData();
  }, [enqueueSnackbar]);

  return { roles }; 
};
