import { decodeBase64ToBuffer } from "src/utils/decode-base64";
import AWS from "./config";

const s3 = new AWS.S3();

async function uploadPhotoToS3(photoData, bucketName, keyName) {
  // Extract base64 data from data URI
  const base64Data = photoData.replace(/^data:image\/\w+;base64,/, '');
  // Convert base64 to ArrayBuffer
  const arrayBuffer = decodeBase64ToBuffer(base64Data);

  const params = {
    Bucket: bucketName,
    Key: keyName,
    Body: arrayBuffer,
    ContentEncoding: 'base64',
    ContentType: 'image/png'  // Adjust content type as per your image type
  };

  try {
    const data = await s3.upload(params).promise();
    return data.Location;
  } catch (err) {
    throw new Error(err.message);
  }
}

// Export function
export { uploadPhotoToS3 };
