import AWS from "./config";

const s3 = new AWS.S3();

async function deletePhotoFromS3(bucketName, keyName) {
  const params = {
    Bucket: bucketName,
    Key: keyName,
  };

  try {
    const data = await s3.deleteObject(params).promise();
    return data;  // Return data if needed
  } catch (err) {
    throw new Error(err.message);
  }
}

// Export function
export { deletePhotoFromS3 };
